import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

import { Blur } from "../../components/Blur";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { usePayTransparencyFlag } from "../lib/usePayTransparencyFlag";
import { type PayRate } from "../types/payRate";
import { formatPayRange } from "../utils";

interface PlacementCardPayProps {
  payRate: PayRate | undefined;
  label: string | undefined;
  loadingPayData: boolean;
  onClick: () => void;
}

export function PlacementCardPay(props: PlacementCardPayProps) {
  const { payRate, label, loadingPayData, onClick } = props;

  const payRateExperiment = usePayTransparencyFlag();
  const isBlurred = payRate?.externallyDerived && payRate?.obscured;
  const visible = (payRate?.exact ?? payRate?.max ?? payRate?.min ?? 0) > 0 || isBlurred;

  if (!visible) {
    return null;
  }

  return (
    <Stack
      alignItems="flex-end"
      flexWrap="nowrap"
      flexShrink={0}
      sx={{
        padding: 4,
        border: "0.5px solid",
        borderColor: (theme) => theme.palette.border?.primary,
        borderRadius: 4,
      }}
      onClick={(event) => {
        if (payRateExperiment.enabled && payRate?.externallyDerived) {
          event.preventDefault();
          onClick();
        }
      }}
    >
      {loadingPayData ? (
        <LoadingSpinner size="small" />
      ) : (
        <>
          {label && (
            <Text variant="caption" color={(theme) => theme.palette.text.secondary}>
              {label}
            </Text>
          )}

          {payRateExperiment.enabled && payRate?.externallyDerived && !isBlurred && (
            <Stack direction="row" alignItems="baseline">
              <Text semibold variant="h5">
                {formatPayRange(payRate, false)}
              </Text>

              <Text variant="body2" color={(theme) => theme.palette.text.secondary}>
                /hr
              </Text>
            </Stack>
          )}

          {isBlurred && (
            <Stack direction="row" alignItems="center">
              <Text semibold variant="h5">
                $
              </Text>
              <Blur sx={{ width: "80px", height: "1.4rem" }} />
            </Stack>
          )}

          {(!payRateExperiment.enabled || !payRate?.externallyDerived) && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Text semibold variant="body2" textAlign="center" sx={{ fontSize: 13 }}>
                {formatPayRange(payRate, false)}
              </Text>
              <Text variant="body2" color={(theme) => theme.palette.text.secondary}>
                /hr
              </Text>
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
}
