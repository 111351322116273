import { LoadingButton } from "@clipboard-health/ui-react";
import { CbhIcon } from "@src/appV2/redesign/components/CbhIcon";
import {
  type CreatePlacementChatChannelRequest,
  useCreateChatChannel,
} from "@src/appV2/ShiftBlocks/api/useCreateChatChannel";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useHistory } from "react-router-dom";

import { getPlacementChatPath } from "../paths";

interface PlacementChatWithWorkplaceProps {
  placementId: string;
  workplaceId: string;
}

export function PlacementChatWithWorkplace(props: PlacementChatWithWorkplaceProps) {
  const { placementId, workplaceId } = props;
  const history = useHistory();
  const worker = useDefinedWorker();
  const { mutateAsync: createChatChannel, isLoading: isCreatingChatChannel } =
    useCreateChatChannel<CreatePlacementChatChannelRequest>();

  return (
    <LoadingButton
      sx={{ width: "fit-content" }}
      isLoading={isCreatingChatChannel}
      disabled={isCreatingChatChannel}
      variant="outlined"
      size="small"
      color="primary"
      startIcon={isCreatingChatChannel ? undefined : <CbhIcon type="text-bubble" />}
      onClick={async () => {
        await createChatChannel({
          facilityId: workplaceId,
          placementId,
          agentId: worker.userId,
        });

        history.push(getPlacementChatPath(placementId, workplaceId, "chat-with-workplace"));
      }}
    >
      Chat with workplace
    </LoadingButton>
  );
}
