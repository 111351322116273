import { LoadingButton, Text, type UseModalState } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { BottomSheet } from "@src/appV2/redesign/components/BottomSheet";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import {
  addDays,
  addMinutes,
  endOfDay,
  format,
  isAfter,
  isBefore,
  startOfDay,
  startOfWeek,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useState } from "react";

import { CbhIcon } from "../../components/CbhIcon";
import { Divider } from "../../components/Divider";
import { type InterviewAvailability } from "../api/useGetInterviewAvailability";
import { InterviewDateSelector } from "./InterviewDateSelector";
import { InterviewTimeSelector } from "./InterviewTimeSelector";

interface PlacementDetailBookInterviewDialogProps {
  modalState: UseModalState;
  interviewAvailability: InterviewAvailability;
  onBookInterview: (time: { start: Date; end: Date }) => void;
}

export function PlacementDetailBookInterviewDialog(props: PlacementDetailBookInterviewDialogProps) {
  const { modalState, interviewAvailability, onBookInterview } = props;
  const worker = useDefinedWorker();
  // Build a dictionary: "YYYY-MM-DD" => [{ start: Date, end: Date }, ...]
  const availabilityMap = interviewAvailability.reduce<
    Record<string, Array<{ start: Date; end: Date }>>
  >((accumulator, slot) => {
    const startDate = format(slot.start, "yyyy-MM-dd");
    if (!accumulator[startDate]) {
      accumulator[startDate] = [];
    }

    accumulator[startDate].push({
      start: slot.start,
      end: slot.end,
    });
    return accumulator;
  }, {});

  const now = utcToZonedTime(new Date(), worker.tmz);
  // Minimum of 30 minutes in the future
  const minimumBookingTime = addMinutes(now, 30);

  /**
   * Given a date, return all valid time slots (by start time).
   * Each slot is returned as a Date of the "start" field.
   */
  const generateTimeSlots = (date: Date) => {
    const dateKey = format(date, "yyyy-MM-dd");
    const dailySlots = availabilityMap[dateKey] || [];
    // Filter out slots that start before the minimum booking time
    return dailySlots.filter((slot) => isAfter(slot.start, minimumBookingTime));
  };

  /**
   * Check if a given date has at least one valid time slot.
   */
  const hasValidTimeSlots = (date: Date) => {
    const slots = generateTimeSlots(date);
    return slots.length > 0;
  };

  /**
   * Returns up to 2 future dates (within `daysToCheck` days from `startDate`)
   * that have at least one valid slot.
   */
  const getNextAvailableDates = (startDate: Date, daysToCheck: number) => {
    const dates: Date[] = [];
    let currentDate = startDate;
    for (let index = 0; index < daysToCheck && dates.length < 2; index += 1) {
      if (hasValidTimeSlots(currentDate)) {
        dates.push(currentDate);
      }

      currentDate = addDays(currentDate, 1);
    }

    return dates;
  };

  // Initialize which days are available in the near future
  const initialDates = getNextAvailableDates(now, 7);
  // We'll use the second found date as our "maxDate", or fallback as needed
  const maxDate = initialDates[1] || initialDates[0] || addDays(now, 1);

  // Our currently selected date and time
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(initialDates[0] || undefined);
  const [selectedTime, setSelectedTime] = useState<{ start: Date; end: Date } | undefined>(
    undefined
  );

  // Generate the list of time slots for the selected date
  const timeSlots = selectedDate ? generateTimeSlots(selectedDate) : [];

  /**
   * Handle when a user clicks on a day in the 7-day calendar UI.
   */
  const handleDateChange = (date: Date) => {
    if (hasValidTimeSlots(date)) {
      setSelectedDate(date);
    } else {
      const nextDates = getNextAvailableDates(date, 7);
      setSelectedDate(nextDates[0] || undefined);
    }
  };

  /**
   * Controls whether a day in the calendar is clickable or not.
   */
  const shouldDisableDate = (date: Date) => {
    // Past the current day?
    if (isBefore(date, startOfDay(now))) {
      return true;
    }

    // Past the maximum day we've identified?
    if (isAfter(date, endOfDay(maxDate))) {
      return true;
    }

    // If it has no valid time slots, disable it
    return !hasValidTimeSlots(date);
  };

  // Start the week on whatever day "now" is on.
  // This is just mimicking the original behavior.
  const weekStart = startOfWeek(initialDates[0] || now, {
    weekStartsOn: (initialDates[0] || now).getDay() as 0 | 1 | 2 | 3 | 4 | 5 | 6,
  });

  // We display exactly 7 days in the top row, starting from `weekStart`
  // We display exactly 7 days in the top row, starting from `weekStart`
  const weekDays = Array.from({ length: 7 }, (_, index) => addDays(weekStart, index));

  const isInterviewSlotAvailable = interviewAvailability.length > 0;

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            modalState.closeModal();
          }}
        >
          {isInterviewSlotAvailable && (
            <LoadingButton
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              isLoading={false}
              disabled={!selectedDate || !selectedTime}
              onClick={async () => {
                if (!selectedTime) {
                  return;
                }

                onBookInterview(selectedTime);
              }}
            >
              Book an interview
            </LoadingButton>
          )}
        </DialogFooter>
      }
    >
      <Stack spacing={6} sx={{ px: 6, py: 8 }}>
        {isInterviewSlotAvailable ? (
          <>
            <Text variant="h4" align="left">
              Choose your interview time
            </Text>
            <InterviewDateSelector
              weekDays={weekDays}
              selectedDate={selectedDate}
              shouldDisableDate={shouldDisableDate}
              onDateChange={handleDateChange}
            />
            <Divider />

            <InterviewTimeSelector
              timeSlots={timeSlots}
              selectedTime={selectedTime}
              onTimeSelect={setSelectedTime}
            />
          </>
        ) : (
          <Stack
            spacing={4}
            sx={{ p: 2, height: "300px" }}
            alignItems="center"
            justifyContent="center"
          >
            <CbhIcon type="info" color={(theme) => theme.palette.warning.main} size="large" />
            <Text semibold variant="h5" align="center">
              No interview slots are available at this time. Please check back later.
            </Text>
          </Stack>
        )}
      </Stack>
    </BottomSheet>
  );
}
